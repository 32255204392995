import React from 'react'
import Routers from './routers'

const App = () => {
  return (
    <Routers />
  )
}

export default App
