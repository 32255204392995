import { errorToaster } from "../utils/Helper";

export const getMethodService = async (url,token = "", show_toast = true) => {
    const response = await fetch(url,{
        method: "GET",
        headers: {
            'Accept': "application/json",
            'Content-Type': "application/json",
            "Authorization" : `Bearer ${token}`,
        }
    });
    const resData = await response.json();
    if(resData.con){
        return {
            status: 'success',
            data: resData.data
        };
    }else{
        if(show_toast){
            errorToaster(resData?.message);
        };
    };
};

export const postMethodService = async (url,data,token = "",show_toast = true) => {
    try
    {
        const response = await fetch(url,{
            method: "POST",
            headers: {
                'Accept': "application/json",
                'Content-Type': "application/json",
                "Authorization" : `Bearer ${token}`,
            },
            body: JSON.stringify(data),
        });
        const resData = await response.json();
        if(resData.con){
            return {
                status: 'success',
                data: resData.data
            };
        }else{
            if(show_toast){
                errorToaster(resData?.message);
            };
        };
    }
    catch(err){
        errorToaster(err?.message);
    }
};

export const patchMethodService = async (url,data,token = "",show_toast = true) => {
    try
    {
        const response = await fetch(url,{
            method: "PATCH",
            headers: {
                'Accept': "application/json",
                'Content-Type': "application/json",
                "Authorization" : `Bearer ${token}`,
            },
            body: JSON.stringify(data),
        });
        const resData = await response.json();
        if(resData.con){
            return {
                status: 'success',
                data: resData.data
            };
        }else{
            if(show_toast){
                errorToaster(resData?.message);
            }; 
        };
    }
    catch(err){
        errorToaster(err?.message);
    }
};