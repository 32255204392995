import React from 'react'
import { Navigate } from 'react-router-dom';

const Protect = ({children}) => {
  const goldAuth = localStorage.getItem('gd_a');
  const royalAuth = localStorage.getItem('rt_a');
  if(goldAuth && royalAuth){
    return children;
  }else{
    return <Navigate to="/login" />
  };
};

export default Protect;
