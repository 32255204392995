const gold_url = process.env.REACT_APP_GOLD_DOMAIN;
const royal_thai_url = process.env.REACT_APP_ROYAL_THAI_DOMAIN;


// GOLD 2D APIS
export const GOLD_LOGIN_API = `${gold_url}login`;
export const GOLD_CHANGE_PASS_API = `${gold_url}change-password`;
export const GOLD_CUSTOM_API = `${gold_url}custom`;
export const GOLD_CUSTOM_EDIT_API = id => `${gold_url}custom/edit/${id}`;
export const GOLD_RESULT_API = `${gold_url}number`;
export const GOLD_CLOSE_API = `${gold_url}close`;
export const GOLD_CLOSE_EDIT_API = id =>`${gold_url}close/edit/${id}`;

// ROYAL THAI 2D APIS
export const ROYAL_THAI_LOGIN_API = `${royal_thai_url}login`;
export const ROYAL_THAI_CHANGE_PASS_API = `${royal_thai_url}change-password`;
export const ROYAL_THAI_CUSTOM_API = `${royal_thai_url}custom`;
export const ROYAL_THAI_CUSTOM_EDIT_API = id => `${royal_thai_url}custom/edit/${id}`;
export const ROYAL_THAI_RESULT_API = `${royal_thai_url}number`;
export const ROYAL_THAI_CLOSE_API = `${royal_thai_url}close`;
export const ROYAL_THAI_CLOSE_EDIT_API = id =>`${royal_thai_url}close/edit/${id}`;