import React, { useEffect, useState } from 'react';
import { FiSend } from "react-icons/fi";
import classes from '../assets/styles/Dashboard.module.css';
import { useNavigate } from 'react-router-dom';
import { errorToaster, successToaster } from '../utils/Helper';
import { ToastContainer } from 'react-toastify';
import { getMethodService, patchMethodService, postMethodService } from '../services';
import { GOLD_CLOSE_API, GOLD_CLOSE_EDIT_API, ROYAL_THAI_CLOSE_API, ROYAL_THAI_CLOSE_EDIT_API, ROYAL_THAI_CUSTOM_API, ROYAL_THAI_CUSTOM_EDIT_API, ROYAL_THAI_RESULT_API, GOLD_RESULT_API, GOLD_CUSTOM_API, GOLD_CUSTOM_EDIT_API } from '../services/constants';
import moment from 'moment-timezone';

const Dashboard = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [result,setResult] = useState("");
    const [goldResult,setGoldResult] = useState("");
    const [royalCustomResults,setRoyalCustomResults] = useState([]);
    const [goldCustomResults,setGoldCustomResults] = useState([]);
    const [royalTime,setRoyalTime] = useState("");
    const [goldTime,setGoldTime] = useState("");
    const [royalCloseStatus,setRoyalCloseStatus] = useState();
    const [goldCloseStatus,setGoldCloseStatus] = useState();

    const [result9,setResult9] = useState(null);
    const [result11,setResult11] = useState(null);
    const [result12,setResult12] = useState(null);
    const [result14,setResult14] = useState(null);
    const [result15,setResult15] = useState(null);
    const [result17,setResult17] = useState(null);
    const [result18,setResult18] = useState(null);
    const [result20,setResult20] = useState(null);
    const [result21,setResult21] = useState(null);

    const [goldResult10,setGoldResult10] = useState(null);
    const [goldResult12,setGoldResult12] = useState(null);
    const [goldResult14,setGoldResult14] = useState(null);
    const [goldResult16,setGoldResult16] = useState(null);
    const [goldResult18,setGoldResult18] = useState(null);
    const [goldResult20,setGoldResult20] = useState(null);
    const [goldResult22,setGoldResult22] = useState(null);


    const navigate = useNavigate();
    const currentDate = moment().tz('Asia/Yangon').format('YYYY-MM-DD');
    const timeconvert = moment().tz("Asia/Yangon").format("HH:mm:ss");

    // Royal Thai 2D Handlers & Datas
    const royalDailyResult = async () => {
        await getMethodService(`${ROYAL_THAI_RESULT_API}/filter?date=${currentDate}`)
        .then(async data => {
            setIsLoading(false);
            if(data?.status === 'success'){
                if(data?.data?.length > 0)
                {
                    data?.data?.map(el => {
                        if(el.time === "09:30 AM"){
                            setResult9(el.result);
                        };

                        if(el.time === "11:00 AM"){
                            setResult11(el.result ?? '??');
                        };

                        if(el.time === "12:30 PM"){
                            setResult12(el.result ?? '??');
                        };

                        if(el.time === "02:00 PM"){
                            setResult14(el.result ?? '??');
                        };

                        if(el.time === "03:30 PM"){
                            setResult15(el.result ?? '??');
                        };

                        if(el.time === "05:00 PM"){
                            setResult17(el.result ?? '??');
                        };

                        if(el.time === "06:30 PM"){
                            setResult18(el.result ?? '??');
                        };

                        if(el.time === "08:00 PM"){
                            setResult20(el.result ?? '??');
                        };

                        if(el.time === "09:30 PM"){
                            setResult21(el.result ?? '??');
                        };
                    })
                };
            };
        })
        .catch(err => {errorToaster(err.message);setIsLoading(false)});
    };

    const royalCustomResult = async () => {
        await getMethodService(ROYAL_THAI_CUSTOM_API)
        .then(async data => {
            setIsLoading(false);
            if(data?.status === 'success'){
                setRoyalCustomResults(data?.data);
                if(data?.data?.length > 0)
                {
                    data?.data?.map(el => {
                        if(el.time === "09:30 AM"){
                            setResult9(el.result);
                        };

                        if(el.time === "11:00 AM"){
                            setResult11(el.result ?? '??');
                        };

                        if(el.time === "12:30 PM"){
                            setResult12(el.result ?? '??');
                        };

                        if(el.time === "02:00 PM"){
                            setResult14(el.result ?? '??');
                        };

                        if(el.time === "03:30 PM"){
                            setResult15(el.result ?? '??');
                        };

                        if(el.time === "05:00 PM"){
                            setResult17(el.result ?? '??');
                        };

                        if(el.time === "06:30 PM"){
                            setResult18(el.result ?? '??');
                        };

                        if(el.time === "08:00 PM"){
                            setResult20(el.result ?? '??');
                        };

                        if(el.time === "09:30 PM"){
                            setResult21(el.result ?? '??');
                        };
                    })
                };
            }
        })
        .catch(err => {errorToaster(err.message);setIsLoading(false)});
    };

    const royalClose = async () => {
        await getMethodService(ROYAL_THAI_CLOSE_API)
        .then(async data => {
            setIsLoading(false);
            if(data?.status === 'success'){
                if(data?.data?.length > 0){
                    setRoyalCloseStatus(data?.data[0]);
                }
            }
        })
        .catch(err => {errorToaster(err.message);setIsLoading(false)});
    };

    const royalCloseHandler = async id => {
        const getToken = localStorage.getItem('rt_a');
        let userData = {
            status: !royalCloseStatus.status
        };
        if(getToken){
            let data = await patchMethodService(ROYAL_THAI_CLOSE_EDIT_API(id),userData,getToken);
            if(data?.status === "success"){
                royalClose();
                if(data?.data?.status){
                    successToaster("Royal Thai Open success!");
                }else{
                    successToaster("Royal Thai Close success!");
                };
            };
        };
    };

    const royalNumberHandler = e => {
        let newValue = e.target.value;
        newValue = newValue.replace(/[e\-+]/, '');
        newValue = newValue.replace(/[^0-9]/g, '');
        if(newValue.length < 3){
            setResult(newValue);
        }else{
            errorToaster("Allow only 2 digits");
        };   
    };

    const royalCreateHandler = async () => {
        const getToken = localStorage.getItem('rt_a');
        let date = moment().tz("Asia/Yangon").format("YYYY-MM-DD");
        let createData = {
            result,
            time: royalTime,
            date
        };
        if(createData.result)
        {
           if(createData.result.length !== 2){
                return errorToaster("Result digits must be 2");
           } 
        }else{
            return errorToaster("Result digits field is required!");
        };
        if(!createData.time)
        {
            return errorToaster("Lottery time field is required!");
        };
        if(getToken){
            let findResult = royalCustomResults.find(result => result.time === royalTime);
            if(findResult){
                let data = await patchMethodService(ROYAL_THAI_CUSTOM_EDIT_API(findResult._id),{result},getToken);
                if(data?.status === "success"){
                    royalCustomResult();
                    setRoyalTime("");
                    setResult("");
                    successToaster("Edit success!");
                };
            }else{
                let data = await postMethodService(ROYAL_THAI_CUSTOM_API,createData, getToken);
                if(data?.status === "success"){
                    royalCustomResult();
                    setRoyalTime("");
                    setResult("");
                    successToaster("Create success!");
                };
            };
        }else{
            navigate('/login');
        };
    };

    // Gold 2D Handlers & Datas

    const goldDailyResult = async () => {
        await getMethodService(`${GOLD_RESULT_API}/filter?date=${currentDate}`)
        .then(async data => {
            setIsLoading(false);
            if(data?.status === 'success'){
                if(data?.data?.length > 0)
                {
                    data?.data?.map(el => {
                        if(el.time === "10:00 AM"){
                            setGoldResult10(el.result);
                        };

                        if(el.time === "12:00 PM"){
                            setGoldResult12(el.result ?? '??');
                        };

                        if(el.time === "02:00 PM"){
                            setGoldResult14(el.result ?? '??');
                        };

                        if(el.time === "04:00 PM"){
                            setGoldResult16(el.result ?? '??');
                        };

                        if(el.time === "06:00 PM"){
                            setGoldResult18(el.result ?? '??');
                        };

                        if(el.time === "08:00 PM"){
                            setGoldResult20(el.result ?? '??');
                        };

                        if(el.time === "10:00 PM"){
                            setGoldResult22(el.result ?? '??');
                        };
                    })
                };
            };
        })
        .catch(err => {errorToaster(err.message);setIsLoading(false)});
    };

    const goldCustomResult = async () => {
        await getMethodService(GOLD_CUSTOM_API)
        .then(async data => {
            setIsLoading(false);
            if(data?.status === 'success'){
                setGoldCustomResults(data?.data);
                if(data?.data?.length > 0)
                {
                    data?.data?.map(el => {
                        if(el.time === "10:00 AM"){
                            setGoldResult10(el.result);
                        };

                        if(el.time === "12:00 PM"){
                            setGoldResult12(el.result ?? '??');
                        };

                        if(el.time === "02:00 PM"){
                            setGoldResult14(el.result ?? '??');
                        };

                        if(el.time === "04:00 PM"){
                            setGoldResult16(el.result ?? '??');
                        };

                        if(el.time === "06:00 PM"){
                            setGoldResult18(el.result ?? '??');
                        };

                        if(el.time === "08:00 PM"){
                            setGoldResult20(el.result ?? '??');
                        };

                        if(el.time === "10:00 PM"){
                            setGoldResult22(el.result ?? '??');
                        };
                    });
                };
            }
        })
        .catch(err => {errorToaster(err.message);setIsLoading(false)});
    };

    const goldClose = async () => {
        await getMethodService(GOLD_CLOSE_API)
        .then(async data => {
            setIsLoading(false);
            if(data?.status === 'success'){
                if(data?.data?.length > 0){
                    setGoldCloseStatus(data?.data[0]);
                }
            }
        })
        .catch(err => {errorToaster(err.message);setIsLoading(false)});
    };

    const goldCloseHandler = async id => {
        const getToken = localStorage.getItem('gd_a');
        let userData = {
            status: !goldCloseStatus.status
        };
        if(getToken){
            let data = await patchMethodService(GOLD_CLOSE_EDIT_API(id),userData,getToken);
            if(data?.status === "success"){
                goldClose();
                if(data?.data?.status){
                    successToaster("Gold Open success!");
                }else{
                    successToaster("Gold Close success!");
                };
            };
        };
    };

    const goldNumberHandler = e => {
        let newValue = e.target.value;
        newValue = newValue.replace(/[e\-+]/, '');
        newValue = newValue.replace(/[^0-9]/g, '');
        if(newValue.length < 3){
            setGoldResult(newValue);
        }else{
            errorToaster("Allow only 2 digits");
        };   
    };

    const goldCreateHandler = async () => {
        const getToken = localStorage.getItem('gd_a');
        let date = moment().tz("Asia/Yangon").format("YYYY-MM-DD");
        let createData = {
            result: goldResult,
            time: goldTime,
            date
        };
        if(createData.result)
        {
           if(createData.result.length !== 2){
                return errorToaster("Result digits must be 2");
           } 
        }else{
            return errorToaster("Result digits field is required!");
        };
        if(!createData.time)
        {
            return errorToaster("Lottery time field is required!");
        };
        if(getToken){
            let findResult = goldCustomResults.find(result => result.time === goldTime);
            if(findResult){
                let data = await patchMethodService(GOLD_CUSTOM_EDIT_API(findResult._id),{result:goldResult},getToken);
                if(data?.status === "success"){
                    goldCustomResult();
                    setGoldTime("");
                    setGoldResult("");
                    successToaster("Edit success!");
                };
            }else{
                let data = await postMethodService(GOLD_CUSTOM_API,createData, getToken);
                if(data?.status === "success"){
                    goldCustomResult();
                    setGoldTime("");
                    setGoldResult("");
                    successToaster("Create success!");
                };
            };
        }else{
            navigate('/login');
        };
    };

    useEffect(()=>{
        royalDailyResult();
        royalCustomResult();
        royalClose();

        goldDailyResult();
        goldCustomResult();
        goldClose();
    },[]);

    const logoutHandler = () => {
        const getGoldAuth = localStorage.getItem('gd_a');
        const getRoyalThaiAuth = localStorage.getItem('rt_a');
        if(getGoldAuth && getRoyalThaiAuth){
            successToaster("Logout success");
            setTimeout(()=>{
                localStorage.removeItem('gd_a');
                localStorage.removeItem('rt_a');
                navigate('/login')
            },1500);
        };
    };

  return (
    <>
        <div className='flex flex-col sm:flex-row items-center sm:items-start justify-center'>
            <div className={classes.boxs}>
                <h1 className='flex items-center'>
                    Royal Thai 2D
                    <label className={classes.switchContainer}>
                        <input className={classes.switchInput} type="checkbox" checked={royalCloseStatus?.status} onChange={()=>royalCloseHandler(royalCloseStatus?._id)} id="togBtn" />
                        <div className={`${classes.slider} ${classes.switchToggle}`}></div>
                    </label>
                </h1>
                <select value={royalTime} onChange={e => setRoyalTime(e.target.value)}>
                    <option value="" selected>Choose Lottery</option>
                    {
                        timeconvert < "09:29:00" && <option value="09:30 AM">09:30 AM</option>
                    }
                    {
                        timeconvert < "10:59:00" && <option value="11:00 AM">11:00 AM</option>
                    }
                    {
                        timeconvert < "12:29:00" && <option value="12:30 PM">12:30 PM</option>
                    }
                    {
                        timeconvert < "13:59:00" && <option value="02:00 PM">02:00 PM</option>
                    }
                    {
                        timeconvert < "15:29:00" && <option value="03:30 PM">03:30 PM</option>
                    }
                    {
                        timeconvert < "16:59:00" && <option value="05:00 PM">05:00 PM</option>
                    }
                    {
                        timeconvert < "18:29:00" && <option value="06:30 PM">06:30 PM</option>
                    }
                    {
                        timeconvert < "19:59:00" && <option value="08:00 PM">08:00 PM</option>
                    }
                    {
                        timeconvert < "21:29:00" && <option value="09:30 PM">09:30 PM</option>
                    }
                </select>
                <div className='relative w-full'>
                        <input type='text' value={result} placeholder='Pauk' onChange={royalNumberHandler}/>
                        <span className='rotate-45 absolute top-3 right-6'>
                            <FiSend size="22px" onClick={royalCreateHandler}/>
                        </span>
                </div>
                <table className={classes.table}>
                    <tbody>
                        <tr>
                            <td>09:30 AM</td>
                            <td>{result9 ?? '--'}</td>
                        </tr>
                        <tr>
                            <td>11:00 AM</td>
                            <td>{result11 ?? '--'}</td>
                        </tr>
                        <tr>
                            <td>12:30 PM</td>
                            <td>{result12 ?? '--'}</td>
                        </tr>
                        <tr>
                            <td>02:00 PM</td>
                            <td>{result14 ?? '--'}</td>
                        </tr>
                        <tr>
                            <td>03:30 PM</td>
                            <td>{result15 ?? '--'}</td>
                        </tr>
                        <tr>
                            <td>05:00 PM</td>
                            <td>{result17 ?? '--'}</td>
                        </tr>
                        <tr>
                            <td>06:30 PM</td>
                            <td>{result18 ?? '--'}</td>
                        </tr>
                        <tr>
                            <td>08:00 PM</td>
                            <td>{result20 ?? '--'}</td>
                        </tr>
                        <tr>
                            <td>09:30 PM</td>
                            <td>{result21 ?? '--'}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className={classes.boxs}>
                <h1 className='flex items-center'>
                    Gold 2D
                    <label className={classes.switchContainer}>
                        <input className={classes.switchInput} type="checkbox" checked={goldCloseStatus?.status} onChange={()=>goldCloseHandler(goldCloseStatus?._id)} id="togBtn" />
                        <div className={`${classes.slider} ${classes.switchToggle}`}></div>
                    </label>
                </h1>
                <select value={goldTime} onChange={e => setGoldTime(e.target.value)}>
                    <option value="" selected>Choose Lottery</option>
                    {
                        timeconvert < "09:59:00" && <option value="10:00 AM">10:00 AM</option>
                    }
                    {
                        timeconvert < "11:59:00" && <option value="12:00 PM">12:00 PM</option>
                    }
                    {
                        timeconvert < "13:59:00" && <option value="02:00 PM">02:00 PM</option>
                    }
                    {
                        timeconvert < "15:59:00" && <option value="04:00 PM">04:00 PM</option>
                    }
                    {
                        timeconvert < "17:59:00" && <option value="06:00 PM">06:00 PM</option>
                    }
                    {
                        timeconvert < "19:59:00" && <option value="08:00 PM">08:00 PM</option>
                    }
                    {
                        timeconvert < "21:59:00" && <option value="10:00 PM">10:00 PM</option>
                    }
                </select>
                <div className='relative w-full'>
                    <input type='text' value={goldResult} placeholder='Pauk' onChange={goldNumberHandler}/>
                    <span className='rotate-45 absolute top-3 right-6'>
                        <FiSend size="22px" onClick={goldCreateHandler}/>
                    </span>
                </div>
                <table className={classes.table}>
                    <tbody>
                        <tr>
                            <td>10:00 AM</td>
                            <td>{goldResult10 ?? '--'}</td>
                        </tr>
                        <tr>
                            <td>12:00 PM</td>
                            <td>{goldResult12 ?? '--'}</td>
                        </tr>
                        <tr>
                            <td>02:00 PM</td>
                            <td>{goldResult14 ?? '--'}</td>
                        </tr>
                        <tr>
                            <td>04:00 PM</td>
                            <td>{goldResult16 ?? '--'}</td>
                        </tr>
                        <tr>
                            <td>06:00 PM</td>
                            <td>{goldResult18 ?? '--'}</td>
                        </tr>
                        <tr>
                            <td>08:00 PM</td>
                            <td>{goldResult20 ?? '--'}</td>
                        </tr>
                        <tr>
                            <td>10:00 PM</td>
                            <td>{goldResult22 ?? '--'}</td>
                        </tr>
                    </tbody>
                </table>
                <button className={classes.logout} onClick={()=> navigate('/change-password')}>စကားဝှက်ပြောင်းမည်</button>
                <button className={classes.logout} style={{marginTop: 0}} onClick={logoutHandler}>ထွက်မည်</button>
            </div>
        </div>
        <ToastContainer />
    </>
  )
}

export default Dashboard
