import React, { useState } from 'react'
import { FiEye, FiEyeOff } from "react-icons/fi";
import classes from '../assets/styles/Login.module.css';
import { successToaster } from '../utils/Helper';
import { ToastContainer } from 'react-toastify';
import { patchMethodService } from '../services';
import { GOLD_CHANGE_PASS_API, ROYAL_THAI_CHANGE_PASS_API, } from '../services/constants';
import { useNavigate } from 'react-router-dom';

const ChangePassword = () => {
  const [oldPassword,setOldPassword] = useState("");
  const [newPassword,setNewPassword] = useState("");
  const [isLoading,setIsLoading] = useState(false);
  const [oldToggleEye,setOldToggleEye] = useState(false);
  const [newToggleEye,setNewToggleEye] = useState(false);
  const navigate = useNavigate();

  const changePasswordHandler = async e => {
    e.preventDefault();
    setIsLoading(true);
    const userData = {
        oldpass: oldPassword,
        newpass: newPassword
    }
    const getGoldAuth = localStorage.getItem('gd_a');
    const getRoyalThaiAuth = localStorage.getItem('rt_a');
    if(getGoldAuth && getRoyalThaiAuth){
        setIsLoading(false);
        let royal = await patchMethodService(ROYAL_THAI_CHANGE_PASS_API,userData,getRoyalThaiAuth,false);
        let gold = await patchMethodService(GOLD_CHANGE_PASS_API,userData,getGoldAuth);
        if(royal?.status === 'success' && gold?.status === 'success' ){
            successToaster("Success");
            setTimeout(async ()=>{
                localStorage.removeItem('gd_a');
                localStorage.removeItem('rt_a');
                navigate('/login');
            },1500);
        };
    }else{
        navigate('/login');
    };
 };

  return (
    <>
        <div>
            {/* <div className={classes.background}>
                <div className={classes.shape}></div>
                <div className={classes.shape}></div>
            </div> */}
            <form className={classes['login-form']} onSubmit={changePasswordHandler}>
                <h3>Change Password</h3>

                <label htmlFor="password">Old Password</label>
                <div className='relative'>
                    <input type={oldToggleEye ? "text":"password"} value={oldPassword} placeholder="Old Password" id="password" onChange={e => setOldPassword(e.target.value)}/>
                    <span className='absolute top-4 right-3' onClick={() => setOldToggleEye(!oldToggleEye)}>
                        {
                            oldToggleEye? 
                            <FiEye size={19} />:
                            <FiEyeOff size={19} />
                        }
                    </span>
                </div>

                <label htmlFor="password">New Password</label>
                <div className='relative'>
                    <input type={newToggleEye ? "text":"password"} value={newPassword} placeholder="New Password" id="password" onChange={e => setNewPassword(e.target.value)}/>
                    <span className='absolute top-4 right-3' onClick={() => setNewToggleEye(!newToggleEye)}>
                        {
                            newToggleEye? 
                            <FiEye size={19} />:
                            <FiEyeOff size={19} />
                        }
                    </span>
                </div>

                <button type='submit' disabled={isLoading}>
                    {
                        isLoading? "Loading...":"Confirm"
                    }
                </button>
            </form>
        </div>
        <ToastContainer />
    </>
  )
}

export default ChangePassword;
