import React from 'react'
import { Routes, Route } from "react-router-dom";
import Login from '../auth/Login';
import Dashboard from '../components/Dashboard';
import Protect from './Protect';
import ChangePassword from '../auth/ChangePassword';

const Routers = () => {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/change-password" element={
        <Protect>
          <ChangePassword />
        </Protect>} 
      />
      <Route path="/" element={
        <Protect>
          <Dashboard />
        </Protect>} 
      />
    </Routes>
  )
}

export default Routers;
