import React, { useState } from 'react';
import { FiEye, FiEyeOff } from "react-icons/fi";
import classes from '../assets/styles/Login.module.css';
import { errorToaster, successToaster } from '../utils/Helper';
import { ToastContainer } from 'react-toastify';
import { postMethodService } from '../services';
import { GOLD_LOGIN_API, ROYAL_THAI_LOGIN_API } from '../services/constants';
import { useNavigate } from 'react-router-dom';

const Login = () => {
  const [email,setEmail] = useState("");
  const [password,setPassword] = useState("");
  const [isLoading,setIsLoading] = useState(false);
  const [toggleEye,setToggleEye] = useState(false);
  const navigate = useNavigate();

  const loginHandler = async e => {
     e.preventDefault();
     setIsLoading(true);
     const userData = {
        email,
        password
     }
     await postMethodService(GOLD_LOGIN_API,userData)
     .then(async data => {
        setIsLoading(false);
        if(data){
            let goldAuth = data?.data?.token;
            await postMethodService(ROYAL_THAI_LOGIN_API,userData)
            .then(royalData => {
                if(royalData){
                    let royalThaiAuth = royalData?.data?.token;
                    if(goldAuth && royalThaiAuth){
                        localStorage.setItem('gd_a', goldAuth);
                        localStorage.setItem('rt_a', royalThaiAuth);
                        successToaster("Login success!");
                        setTimeout(()=>{
                            setEmail("");
                            setPassword("");
                            navigate('/');
                        },1500);
                    };
                };
            })
            .catch(err => {errorToaster(err.message);setIsLoading(false);});
        };
     })
     .catch(err => {errorToaster(err.message);setIsLoading(false);});
  };
  return (
    <>
        <div>
            <div className={classes.background}>
                <div className={classes.shape}></div>
                <div className={classes.shape}></div>
            </div>
            <form className={classes['login-form']} onSubmit={loginHandler}>
                <h3>Login</h3>

                <label htmlFor="email">Email</label>
                <input type="text" placeholder="Email" value={email} id="email" onChange={e => setEmail(e.target.value)}/>

                <label htmlFor="password">Password</label>
                <div className='relative'>
                    <input type={toggleEye ? "text":"password"} value={password} placeholder="Password" id="password" onChange={e => setPassword(e.target.value)}/>
                    <span className='absolute top-4 right-3' onClick={() => setToggleEye(!toggleEye)}>
                        {
                            toggleEye? 
                            <FiEye size={19} />:
                            <FiEyeOff size={19} />
                        }
                    </span>
                </div>

                <button type='submit' disabled={isLoading}>
                    {
                        isLoading? "Loading...":"Log In"
                    }
                </button>
            </form>
        </div>
        <ToastContainer />
    </>
  )
}

export default Login
